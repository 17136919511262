import * as React from 'react'
import { QuantumEventNode } from 'types'

type EventBoxProps = {
  event: QuantumEventNode
  key: number
}

const EventBox = ({ event, key }: EventBoxProps) => {
  return (
    <div className="w-full lg:w-1/3 p-10.5" key={key}>
      <h5 className="h5-sm">
        <a
          href={event.URL}
          aria-label={`${event.Name}, ${event.Date}`}
          target="_blank"
          rel="noreferrer"
        >
          {event.Name}
        </a>
      </h5>
      {event.Location}
      <br />
      <a
        href={event.URL}
        className="block mt-5 font-semibold border-b-2 swipe-link swipe-link-2 border-primary-600"
        aria-label={`${event.Name}, ${event.Date}`}
        target="_blank"
        rel="noreferrer"
      >
        <span data-hover={event.Date}>{event.Date}</span>
      </a>
    </div>
  )
}

export default EventBox
