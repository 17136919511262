import * as React from 'react'
import { QuantumEventNode } from 'types'
import EventBox from './EventBox'

type EventBoxesProps = {
  events: QuantumEventNode[]
}

const EventBoxes = ({ events }: EventBoxesProps) => {
  return (
    <div className="flex flex-row flex-wrap -m-10.5">
      {events.map((item, index) => {
        return <EventBox event={item} key={index} />
      })}
    </div>
  )
}

export default EventBoxes
