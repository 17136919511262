/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import EventBoxes from '../components/2025InternationalYearOfQuantum/EventBoxes'
import Seo from '../components/Seo'
import { QuantumEventNode } from 'types'

type QuantumEventPageProps = {
  data: {
    allIyoqJson: { nodes: QuantumEventNode[] }
  }
}

const QuantumEventPage = ({ data }: QuantumEventPageProps) => {
  const events = data.allIyoqJson.nodes
  return (
    <Layout>
      {/* HERO */}
      <div className="py-32 bg-iyoq-tbd">
        <div className="inner-container">
          <p className="text-white subtitle opacity-70">// Press</p>
          <div className="text-white lg:w-5/12">
            <h1>2025 International Year of Quantum</h1>
          </div>
        </div>
      </div>

      {/* ALL PRESS */}
      <div className="overflow-hidden standard-page-section">
        <div className="inner-container">
          <h2>Events</h2>
          <h4>Connect with Xanadu</h4>
          <div className="mt-20">
            <EventBoxes events={events} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allIyoqJson {
      nodes {
        Name
        Date
        Location
        URL
      }
    }
  }
`

export default QuantumEventPage

export const Head = () => {
  return (
    <Seo
      title="2025 International Year of Quantum"
      description="2025 International Year of Quantum"
      image="2025YearOfQuantum_hero_bg.png"
    />
  )
}
